import React, { useRef, useState, useEffect } from 'react';
import './App.scss';


import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  apiKey: "AIzaSyDSEs7nAkFc_FTGOoX8X0lDJ5lG9mTupko",
  authDomain: "superchat-1d81e.firebaseapp.com",
  projectId: "superchat-1d81e",
  storageBucket: "superchat-1d81e.appspot.com",
  messagingSenderId: "330522842635",
  appId: "1:330522842635:web:e1f25de992fcb51a07990e",
  measurementId: "G-GX7VZSL9P2"
})

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

analytics.logEvent(`A user is using Superchat Now!`);


function App() {
  const [user] = useAuthState(auth);

  return (
    <div className="App min-vh-100">
      <div className="min-vh-100 container-md">
        {/* <header className="row mx-auto w-100 py-1">

        </header> */}
          
        <section className="min-vh-100 w-100">
          {user ? <ChatRoom /> : <SignIn />}
        </section>
      </div>
    </div>
  );
}


function SignIn(){
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  } 

  return (
    <div className="row min-vh-100">
      <div className="col-12 my-auto mx-auto text-center">
        <img className="img-fluid custom-logo-login rounded-circle border-0" src="https://ems-dev-test.s3-ap-southeast-1.amazonaws.com/livehive/ooz-logo.jpg" alt="Logo"/>
        <p className="my-2 h5 fw-bold mx-auto">OOZ Public Chat</p>
        <button className="sign-in btn btn-outline-light px-5 fw-bold" onClick={signInWithGoogle}><i className="bi bi-google"></i>&nbsp;&nbsp; Sign in with Google</button>
        <p className="text-center mx-auto">Do not violate the community guidelines!</p>
      </div>
    </div>
  )
}

function ChatRoom(){

  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt');

  const [messages] = useCollectionData(query, {idField: 'id'});

  const [formValue, setFormValue] = useState('');

  const sendMessage = async(e) => {
    e.preventDefault();

    const { email, displayName, uid, photoURL } = auth.currentUser;

    await messagesRef.add({
       text: formValue,
       createdAt: firebase.firestore.FieldValue.serverTimestamp(),
       email,
       displayName,
       uid,
       photoURL
    });

    setFormValue('');

    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <main className="row bg-custom-dark">
      <header className="row p-0 m-0">
        <div className="col-8 my-auto">
          <p className="mb-0 p-0 fw-bold"><img className="img-fluid custom-logo rounded-circle border-0" src="https://ems-dev-test.s3-ap-southeast-1.amazonaws.com/livehive/ooz-logo.jpg" alt="Logo"/> &nbsp; OOZ Public Chat</p>
        </div>

        <div className="col-4 sign-out-area my-auto">
          <SignOut/>
        </div>
      </header>


      <div className="col-12 w-100 px-1 pt-3 pb-0 mb-0 px-md-5 recieve-message-form">
          {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} placeholder="Say something nice!" />)}
          <div ref={dummy} className="custom-empty"></div>
      </div>

      <div className="col-12 w-100 p-0 m-0 send-message-form d-flex align-content-end flex-wrap">
        <form onSubmit={sendMessage} className="w-100 h-100">
          <div className="input-group h-100">
              <input className="form-control input-send" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Say something nice!"/>
            <div className="input-group-append">
              <button type="submit" disabled={!formValue} className="btn btn-primary btn-send px-5 h-100" ><span className="h3">💬</span></button>
            </div>
          </div>
        </form>
      </div>
    </main>
  )
}

function ChatMessage(props) {
  const { displayName, text, uid, photoURL, createdAt } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  if (createdAt !== null){
    var toDate = createdAt.toDate().toLocaleDateString("en-PH");
    var toTime = createdAt.toDate().toLocaleTimeString("en-PH");
  }
  else {

  }

  return (
    <div className={`message ${messageClass}`}>
      <img src={photoURL} className="rounded-circle mt-auto mb-3 profilePhoto" alt="Profile"/>
      <p className="rounded">
        <p className={`p-0 mb-0 chat-username ${messageClass}-name`}>{displayName}</p>
        <p className={`p-0 mb-1 text-uppercase`}> 
          <span className={`${messageClass}-date`}>{toDate}</span> 
          <span className={`${messageClass}-time`}>&nbsp;&nbsp;{toTime} </span>
        </p>
        {/* <p className={`p-0 mb-0 ${messageClass}-time`}> {toTime} </p> */}
        {text}
      </p>
    </div>
  )
}

function SignOut(){
  return auth.currentUser && (
    <button onClick={() => auth.signOut()} className="btn btn-outline-light float-right">Logout</button>
  )
}

export default App;
